.site-header {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 10;
  &__sidebar {
    position: absolute;
    left: 0;
    top: 0;
    height: 7.2rem;
    width: 100vh;
    transform-origin: right top;
    transform: translateX(-100%) rotate(-90deg);
    border-bottom: 1px solid $black;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.2rem;
    padding-bottom: 2px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background: $black;
    } button {
      @include reset-appearance;
      transform: rotate(90deg);
    }

    @media screen and (max-width: 560px) {
      display: none;
    }
  }

  &__navbar {
    position: absolute;
    left: 7.2rem;
    top: 0;
    width: calc(100vw - 7.2rem);
    height: 3.2rem;
    border-bottom: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: $white;
    @media screen and (max-width: 560px) {
      width: 100vw;
      left: 0;
    }
    &__crumb {
      height: 2rem;
      @include flex(1,0,0);
      margin-left: 0.8rem;
      border: 1px solid rgba($black, 0.5);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 1rem;
      padding: 0 1.2rem;
      @include manrope-style;
    } svg {
      stroke: none;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
