/*
 * Responsive Breakpoints
 ***************************/
$mobile_max: 767px;
$tablet_min: 768px;
$tablet_max: 1024px;
$desktop_min: 1025px;
$desktop_max: 1439px;
$widescreen_min: 1440px;

/*
 * Margins / Max Widths
 ***************************/
$container_mobile_margins: 24px;
$container_desktop_margins: 16px;
$container_desktop_max_width: 959px;
$container_widescreen_margins: 16px;
$container_widescreen_max_width: 1200px;

$content_mobile_margins: 32px;
$content_desktop_margins: 32px;
$content_desktop_max_width: 780px;
$content_widescreen_margins: 32px;
$content_widescreen_max_width: 780px;

$bezier: cubic-bezier(0.64, 0.04, 0.35, 1);
