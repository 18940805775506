.intro-header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  &__text {
    padding: 6.4rem 0;
    @include flex(2,0,0);
    border-right: 1px solid $black;
    p {
      font-family: $amstelvar;
      font-size: 6.4rem;
      line-height: 6.4rem;
      letter-spacing: -0.1rem;
      max-width: 32.8rem;
      margin: 0 auto;
      font-variation-settings: "wght" 300, "wdth" 50, "opsz" 73.58, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -239.36, "YTFI" 758.5;

      b {
        font-variation-settings: "wght" 500, "wdth" 50, "opsz" 73.58, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -239.36, "YTFI" 758.5;
      }

      .st-anim__line-inner {
        b {
          font-variation-settings: "wght" 300, "wdth" 50, "opsz" 73.58, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -239.36, "YTFI" 758.5;
          transition: 300ms;
        }
        &.active {
          b {
            font-variation-settings: "wght" 500, "wdth" 50, "opsz" 73.58, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -239.36, "YTFI" 758.5;
          }
        }
      }
    }
  } &__right {
    @include flex(1,0,0);
    background-image: url('../img/pic2.gif');
    background-size: cover;
    background-position: right center;
  }

  @include for-size(mobile) {
    flex-direction: column;
    .intro-header {
      &__text {
        @include flex(1,1,auto);
        border-right: 0;
      } &__right {
        @include flex(1,1,auto);
      }
    }
  }
}
