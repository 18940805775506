.footer-section {
  padding-bottom: 8rem;
  @include for-size(mobile) {
    padding-left: $content_mobile_margins;
    padding-right: $content_mobile_margins;
  }
  .work-section__splash {
    margin-bottom: 2.4rem;
  }
  &__blurb {
    max-width: 48rem;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    font-weight: 200;
    a {
      color: #26FF00;
      text-decoration: underline;
    }
  } &__contact {
    max-width: 48rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    &-group {
      @include flex(0,0,50%);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 1rem 0;
      &:last-child{
        @include flex(0,0,100%);
      }

      @include for-size(mobile) {
        @include flex(0,0,100%);
      }

      h2 {
        @include flex(0,0,100%);
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 300;
        margin-bottom: 1.2rem;
      } a {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-right: 3.2rem;
        font-weight: 200;
      }
    }
  }
}
