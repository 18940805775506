.site-section {
  &__title {
    border: solid $black;
    border-width: 1px 0;
    @include manrope-style;
    padding: calc(1.2rem - 1px) 0;
    position: sticky;
    top: 3.1rem;
    height: 3.3rem;
    overflow: hidden;
    z-index: 9;
    background: $white;
    &-inner {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > span {
        white-space: nowrap;
        margin: 0 1.6rem;
      }
    }
  }
}
