.site-contact {
  display: flex;
  min-height: calc(100vh - 3.2rem);
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  &__header {
    @include manrope-style;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.2rem;
    border-bottom: 1px solid $black;
    padding: 0 1.6rem;
  } &__messages {
    padding: 1.6rem;
    @include flex(1,0,0);
    padding: 1.6rem;
  } &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $black;
    padding: 0.6rem;
    &-message {
      @include reset-appearance;
      @include flex(1,0,0);
      height: 5.6rem;
      resize: none;
      border: 1px solid rgba($black, 0.5);
      font-family: $amstelvar;
      font-size: 1.6rem;
      letter-spacing: 0;
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
      font-variation-settings: "wght" 300, "wdth" 50, "opsz" 40, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -239.36, "YTFI" 758.5;
      @include placeholder {
        color: $black;
        opacity: 1
      }
    } &-submit {
      width: 5.6rem;
      height: 5.6rem;
      border: 1px solid rgba($black, 0.5);
      border-left: 0;
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 500ms $bezier;
      &:hover, &:focus {
        background-color: #26FF00;
      } span {
        transform: rotate(45deg);
        display: block;
        @include manrope-style;
      }
    }
  }
}

.chat-list {
  &__item {
    p {
      @include manrope-style;
      margin-bottom: 1rem;
      b {
        color: #26FF00;
      }
    }
  }
}
