.site-container {
  padding-left: 7.2rem;
  padding-top: 3.2rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-height: calc(100vh - 3.2rem);
  @media screen and (max-width: 560px) {
    padding-left: 0;
  }
  &__main {
    @include flex(2,0,0);
  } &__aux {
    @include flex(1,0,0);
    border-left: 1px solid $black;
    > * {
      position: sticky;
      top: 3.2rem;
    }
    @media screen and (max-width: 972px) {
      display: none;
    }
  }
}
