.work-section {
  padding :6vh 0;
  &__splash {
    font-family: $amstelvar;
    font-size: 3.2rem;
    letter-spacing: 0;
    text-align: center;
    line-height: 3.2rem;
    max-width: 33.8rem;
    margin: 6.4rem auto;
    font-variation-settings: "wght" 300, "wdth" 50, "opsz" 144, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -200.36, "YTFI" 758.5;

    b {
      font-variation-settings: "wght" 500, "wdth" 50, "opsz" 144, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -200.36, "YTFI" 758.5;
    }

    .st-anim__line-inner {
      b {
        font-variation-settings: "wght" 300, "wdth" 50, "opsz" 144, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -200.36, "YTFI" 758.5;
        transition: 300ms;
      }
      &.active {
        b {
          font-variation-settings: "wght" 500, "wdth" 50, "opsz" 144, "GRAD" 0, "XTRA" 562, "XOPQ" 176, "YOPQ" 124, "YTLC" 499.5, "YTUC" 750, "YTAS" 765.65, "YTDE" -200.36, "YTFI" 758.5;
        }
      }
    }
  }

  > img {
    max-width: 80%;
    margin: 3.2rem auto;
    display: block;
    width: auto;
    height: auto;
  }

  &__table {
    display: block !important;
    width: calc(100% - 9.6rem);
    max-width: 48rem;
    margin: 0 auto;
    thead {
      border-bottom: 1px solid $black;
      padding-bottom: 1.6rem;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        height: 1px;
        width: 100%;
        background: $black;
      } td:last-child {
        display: none !important;
      }
    }
    > * {
      display: block !important;
      width: 100%;
      tr {
        display: flex !important;
        position: relative;
        flex-wrap: wrap;
        width: 100%;
        th,td {
          position: relative;
          @include flex(0,0,50%);
          z-index: 2;
          text-align: left;
        }

        th {
          font-size: 2.4rem;
          line-height: 2.4rem;
        }

        td {
          display: block;
          font-size: 1.6rem;
          line-height: 1.6rem;
          font-weight: 400;
          padding: 0.8rem 0;
          &:last-child {
            @include flex(0,0,100%);
            padding-bottom: 1.6rem;
            font-weight: 100;
          } &:first-child {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 200%;
              height: 1px;
              background-image: radial-gradient(circle, $black 0.5px, transparent 0.5px);
              background-size: 5px 1px;
              background-size: repeat-x;
            }
          }
        }
      }
    }

    &-links {
      padding-top: 0.8rem;
      display: flex;
      justify-content: flex-end;
      a {
        margin-left: 1.2rem;
        @include manrope-style;
        transition: 300ms $bezier;
        &:hover, &:focus {
          color: #26FF00;
        }
      }
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% - 9.6rem);
    max-width: 72rem;
    margin: 0 auto;
    li {
      margin: 0.6rem;
      padding: 0 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      letter-spacing: 0.1rem;
      font-weight: 500;
      text-transform: uppercase;
      height: 3.2rem;
      border-radius: 1.6rem;
      border: 1px solid $black;
    }
  }
}
