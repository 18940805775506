.showcase-flow {
  &__list {
    padding: 0 8rem;
    display: flex;
    flex-direction: column;
    @include for-size(mobile) {
      padding: 0 2.4rem;
    }
    li {
      margin: 0;
      padding: 0;
      @include for-size(mobile) {
        margin: 2.4rem 0 !important;
      }
      img, video {
        max-width: 100%;
        width: auto;
        height: auto;
        display: block;
      }
    }
  }
  padding-bottom: 25vh;
}

#cubacch {
  align-self: flex-start;
  max-width: 56rem;
  margin-left: 3.2rem;
  margin-top: -16rem;
  @include for-size(mobile) {
    margin-top: 0;
  }
}

#tgdrlogo {
  align-self: flex-end;
}

#cdvid {
  max-width: 37.5rem;
  align-self: flex-end;
  margin-top: -25rem;
  > * {
    box-shadow: 0 -3px 8px rgba(0,0,0,0.08);
  }
  @include for-size(mobile) {
    margin-top: 0;
  }
}

#sagavid {
  max-width: 37.5rem;
  align-self: flex-end;
  margin-top: -12rem;
  @include for-size(mobile) {
    margin-top: 0;
  } > * {
    box-shadow: 0 -3px 8px rgba(0,0,0,0.08);
  }
}

#cmykgif {
  
  margin-top: 3.2rem;
  max-width: 54rem;
  align-self: center;
  @include for-size(mobile) {
    margin-top: 0;
  }
}

#aahvid {
  > * {
    box-shadow: 0 -3px 8px rgba(0,0,0,0.08);
  }
  margin-top: -24rem;
  max-width: 54rem;
  align-self: flex-start;
  @include for-size(mobile) {
    margin-top: 0;
  }
}
